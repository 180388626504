import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={1000}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
            {item.subtitle}
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
};

const Partners = () => {
  return (
    <Box>
      <Box marginBottom={1}>
        <FaqGroupItem
          
          items={[
            {
              title: '1.   Name of the School with address',
              subtitle: 
            <table>
              <thead>
              <tr>
                
                  <th>(Strictly as per Affiliation sanction letter or as permitted by the Board) with pin code no.</th>
                
              </tr>
              
              <tr>
                  <th>NATIONAL PUBLIC SCHOOL</th>
              </tr>

              <tr>
                <td>No. 9/1, Pipeline Road, Raghavendra Layout,</td>
              </tr>
              <tr>
                <td>Yeshwanthpur, Bengaluru —560-022</td>
              </tr>
              
              <tr>
                <td>E-mail	:	info@npsypr.edu.in</td>
              </tr>
              <tr>
                <td>Ph. No	:	+91 80 23571220</td>
              </tr>
              </thead>

            </table>    
            },
    
              
            
           
            {
              title: '2.   Year of establishment of school',
              subtitle:
                '2013'            },
            {
              title: '3.  Whether NOC from State / UT or recommendation of Embassy of India obtained',
              subtitle:
            <table>
              <thead>
              <tr>
                  <th>Yes.</th>
              </tr>
              <tr>
                <td>NOC No : ED 31 PGC 2013</td>
              </tr>
              <tr>
                <td>NOC issuing date : 20 - 03 - 2013</td>
              </tr>

              </thead>
              
              
            </table>        
            },
            {
              title: '4.  Is the school recognized, if yes by which authority?',
              subtitle:
                'Central Board of Secondary Education, New Delhi',
            },
            {
              title: '5.   Status of Affiliation: Permanent / Regular / Provisional ?',
              subtitle:
              <table>
                <thead>
                <tr>
                  
                    <th>Provisional</th>
                  
                </tr>
                <tr>
                  <td>Affiliation No : 830531</td>
                </tr>
                <tr>
                  <td>Affiliation with the Board since : 2014</td>
                </tr>
                <tr>
                  <td>Extension of affiliation upto : 2027</td>
                </tr>
                </thead>
              </table>
                
            },
            {
              title: '6.   Name of Trust/ Society/ Company Registered under Section 25 of the Company Act, 1956 and Period upto which Registration of Trust/ Society is valid ?',
              subtitle:
                'PURNAA TRUST (PERMANENT)',
            },
            {
              title: '7.  Name and official address of the Manager / President / Chairman / Correspondent',
              subtitle:
              <table>
                <thead>
                <tr>
                  
                    <th>Dr.R Shiva Shankar</th>
                  
                </tr>
                <tr>
                  <td>E-mail: info@npsypr.edu.in</td>
                </tr>
                <tr>
                  <td>Ph no: +918023571220</td>
                </tr>
                </thead>
              </table>
            },
            {
              title: '8.  Area of School Campus',
              subtitle:
              <table>
              <thead>
              <tr>
                
                  <th></th>
                
              </tr>
              
              <tr>
                  <th>NATIONAL PUBLIC SCHOOL</th>
              </tr>

              <tr>
                <td>In Acres : 2.45 acres</td>
              </tr>
              <tr>
                <td>In Sq. mtrs : 9918.8 sq. mtrs.</td>
              </tr>
              <tr>
                <td>Built up area (sq. mtrs.) : 13006 sq. mtrs</td>
              </tr>

              <tr>
                <td>Area of Playground in Sq. mtrs : 5214.9 sq. mtrs.</td>
              </tr>
              <br/>
              <tr>
                <th>Other facilities :</th>
              </tr>
              <tr>
                <td>Swimming Pool	:	No</td>
              </tr>
              <tr>
                <td>Indoor games	:	Yes</td>
              </tr>
              <tr>
                <td>Dance Rooms	:	Yes</td>
              </tr>
              <tr>
                <td>Gymnasium	:	No</td>
              </tr>
              <tr>
                <td>Music Rooms	:	Yes</td>
              </tr>
              <tr>
                <td>Hostels	:	No</td>
              </tr>
              <tr>
                <td>Health and Medical Check up	:	Yes</td>
              </tr>
              </thead>

            </table>    ,
            },
            {
              title: '9.   Transport facility',
              subtitle:
              <table>
                <thead>
                <tr>
                  
                    <th>Own buses : No</th>
                  
                </tr>
                <tr>
                  <th>Buses hired on contract basis : No</th>
                </tr>
                <tr>
                  <th>Details of transport charges : No</th>
                </tr>
                </thead>
              </table>
                
            },
            {
              title: '10.  Details of fee structure',
              subtitle:
              <table>
                <thead>
                <tr>
                  
                    <th>
                      <td>Pre-KG  -</td>
                      <td></td>
                      <td>  Rs. 171775 /-</td>
                    </th>
                  
                </tr>
                <tr>
                  <th><td>KG  -</td>
                      <td></td>
                      <td>   Rs. 171775/-</td></th>

                </tr>
                <tr>
                  <th><td>I to V -</td>
                      <td></td>
                      <td>  Rs. 171775/-</td></th>
                </tr>
                <tr>
                  <th><td>VI to VIII -</td>
                      <td></td>
                      <td>  Rs. 171775/-</td></th>
                </tr>
                <tr>
                  <th><td>IX to X -</td>
                      <td></td>
                      <td>   Rs. 171775/-</td></th>
                </tr>
                <tr>
                  <th><td>XI to XII ( Average ) -</td>
                      <td></td>
                      <td>   Rs. 154000/-</td></th>
                </tr>
                </thead>
              </table>,
              
            },
            {
              title: '11.   Number of Teaching Staff (to be updated from time to time)',
              subtitle:
              <table>
                <thead>
                <tr>
                  
                  <th>
                    Designation  
                  </th>
                  <th>
                    Total no.
                  </th>
                
              </tr>
                <tr>
                  
                    <th>
                      <td>Principal  -</td></th>
                      
                      <th><td>1</td>
                    </th>
                  
                </tr>
                <tr>
                  <th><td>VP  -</td></th>
                     
                  <th> <td>1</td></th>

                </tr>
                <tr>
                  <th><td>AP  -</td></th>
                     
                  <th> <td>2</td></th>

                </tr>
                <tr>
                  <th><td>NTTs -</td></th>
                     
                      <th><td>11</td></th>
                </tr>
                <tr>
                  <th><td>PRT -</td></th>
                     
                      <th><td> 49</td></th>
                </tr>
                <tr>
                  <th><td>TGT -</td>
                      </th>
                      <th>
                      <td>27</td></th>
                </tr>
                <tr>
                  <th><td>PGT -</td>
                      </th><th>
                      <td>17</td></th>
                </tr>
                <tr>
                  <th><td>PRT (PET) -</td>
                  </th><th>
                      <td>5</td></th>
                </tr>
                <tr>
                  <th><td>Librarian -</td>
                  </th>
                  <th>
                      <td>2</td></th>
                </tr>
                <tr>
                  <th><td>Admin -</td>
                  </th>
                  <th>
                      <td>11</td></th>
                </tr>
                </thead>
              </table>,
              
            },
            
            {
              title: '12.   Details of salary being paid by the school to teaching Staff / non-teaching staff (to be updated time to time)',
              subtitle:
              <table>
              <thead>
              <tr>
                
                <th>
                Designation  
                </th>
                <th>
                Pay Scale 
                </th>
                <th>
                Level
                </th>
              
            </tr>
              <tr>
                
                  <th>
                    <td>Principal  -</td></th>
                    <th><td>77700 - 191000 </td></th>
                    <th> <td>  (12) </td></th>
                  
                
              </tr>
              <tr>
                <th><td>PGT  -</td></th>
                <th> <td>43600 - 96900 </td></th>
                <th> <td>  (8)</td></th>

              </tr>
              <tr>
                <th><td>TGT -</td></th>
                <th> <td>42300 - 94100</td></th>
                <th> <td> (7)</td></th>
              </tr>
              <tr>
                <th><td>PRT, Counsellor, Librarian -</td></th>
                <th><td>33900 - 78500</td></th>
                   <th> <td> (6)</td></th>
              </tr>
             
        
              </thead>
            </table>,
               
            },
            {
              title: '13.  Mode of payment of salary',
              subtitle:
              <table>
                <thead>
                <tr>
                  
                    <th>Name of the Bank through which salary is drawn : The Federal Bank Ltd., Rajajinagar, Bangalore - 560 010</th>
                  </tr><tr>
                  
                    <th>Through single cheque transfer advice : Yes</th>
                    </tr><tr>
                  
                    <th>Individual cheque : NA</th>
                    </tr><tr>
                  
                    <th>Cash : NA</th>
                  
                </tr>
                </thead>
              </table>
                
            },
            {
              title: '14.  Library facilities',
              subtitle:
              <table>
              <thead>
              <tr>
                <td>Size of the Library in sq. feet	:	3552 sq.ft </td>
              </tr>
              <tr>
                <td>No. of Periodicals	:	18</td>
              </tr>
              <tr>
                <td>No. of Dailies :	:	04</td>
              </tr>
              <tr>
                <td>No. of reference books class-wise	:	2767</td>
              </tr>
              <tr>
                <td>No. of Magazine	:	14</td>
              </tr>
              <tr>
                <td>Others	:	5961</td>
              </tr>
              </thead>
              
            </table>
            }, 
                
            {
              title: '15.  Name of the grievance/redressal Officer',
              subtitle:
              <table>
                <thead>
                <tr> <td>Principal </td></tr>
                <tr> <td>Vice Principal </td></tr>
                    <td>Assistant Principals</td>
                   <tr> <td>Co-ordinators </td></tr>
                    <tr><td>E-mail : info@npsypr.edu.in</td></tr>
                   <tr> <td>Ph. No : +91 80 23571220</td></tr>
                </thead>
                
              </table>
                
            },
            {
              title: '16.  Members of Sexual Harassment Committee',
              subtitle:
              <table>
                <thead>
                <tr>
                  
                    <td>Principal</td>
                    </tr><tr>
                    <td>Vice Principal</td>
                    </tr><tr>
                    <td>Assistant Principal</td>
                    </tr><tr>
                    <td>Co-ordinator - Senior classes</td>
                    </tr><tr>
                    <td>Co-ordinator - Junior classes</td>
                
                 
                </tr>
                </thead>
              </table>
                
            },
            {
              title: '17.  Academic session period',
              subtitle:
                '1st June to 31st March',
            },
            {
              title: '18.  Vacation period',
              subtitle:
                '1st April to 31st May',
            },
            {
              title: '19.  Admission period',
              subtitle:
                'From January to April',
            },
            // {
            //   title: '20.  Uniform',
            //   subtitle:
            //   <table>
            //     <thead>
            //     <tr>
                 
            //         <th>For Uniforms, Kindly contact</th>
                 
            //         <td>Vivek Tailors : 23232129 / 32712828</td>
                 
            //         <th>(OR)</th>
                 
            //         <td>Mickey and Minnie Dresses :9845012007 / 9845449332</td>
            //     </tr>
            //     </thead>
            //   </table>
                
            // },
            {
              title: '20.  Transport',
              subtitle:
              <table>
                <thead>
                <tr>
                    <td>For Transport details, Kindly Contact</td>
                
                    <td>Sam Tours & Travels : 26602648 / 25983346</td>
                </tr>
                </thead>
              </table>
                
            },
          ]}
        />
      </Box>
      
      <Typography variant='h6' >For any related question 
      </Typography>
      <Typography>
      <a href='/enquiry'>post them here</a>
      </Typography>
      
    </Box>
  
  );
};

export default Partners;